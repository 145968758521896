import React from "react";
// if i18n one day
// import {injectIntl,FormattedMessage} from 'react-intl';
import {
  Badge,
  // Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import {mimeType} from '../../helper'
// import docxConverter from 'docx-pdf';

const errorComponent= function(error,message){
  const errorList = [];

  for(let e in error){
    // console.log('err',error[e]);
    // if(mimeType(error[e].type) !== 'docx'){
      errorList.push(
        <ListGroupItem 
          key={e} 
          id={e}
          tag="a" 
          download={error[e].filename}
          target="_blank"
          style={{color:"black"}} 
          href={error[e].url}>
            {error[e].name}<Badge 
              color={'warning'} 
              className="float-right">{mimeType(error[e].type)}</Badge>
        </ListGroupItem>);
    // }
    // else{
    //   docxConverter(error[e].url,'./output.pdf',function(err,result){
    //     if(err){
    //       console.log(err);
    //     }
    //     console.log('result'+result);
    //   });
    // }
    
  }

  if(!errorList.length)
    return(<></>);
    
  return (<>
    <label style={{paddingBottom:"15px"}}>
      <h4 style={{color:"red",textAlign:"center"}}>{message}</h4>
      <ListGroup>{errorList}</ListGroup>
    </label>
  </>)
}

export default errorComponent;