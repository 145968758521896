import React from "react";
import { connect } from 'react-redux';
import rootsActions from '../../_actions/root-actions';

import {privateAxios as axios} from "../../utils/axios"
// import axios from "axios";
// reactstrap components
import {
    Label,
    FormGroup,
    Form,
    Input,
} from "reactstrap";
// core components

import {injectIntl, FormattedMessage} from 'react-intl';

class TicketSearch extends React.Component {
    constructor(props){
        super(props);

        this._timeout_id=-1;

        this.state = {
            noResult:false,
            ticket:'',
            text:'',
            text2:'',
            ticket_id:''
        }

        this.getInitialState = this.getInitialState.bind(this);
        this.idleChange = this.idleChange.bind(this);
        this.searchTicket = this.searchTicket.bind(this);
    }

    getInitialState(){

    }

    idleChange(e){
        this.setState({ticket_id:e.target.value});

        if(e.target.value.length > 4){
            clearTimeout(this._timeout_id);
            let t = setTimeout( () => this.searchTicket(),1200);
            this._timeout_id = t;
        }
    }

    searchTicket(e){
        if(e)
            e.preventDefault();


        axios.get(
            '/ticket',{
            params:{
                data:this.state.ticket_id,
                source:this.props.source,
                AUTH_ID:localStorage.getItem('user_id')
            }
        })
        .then((response) => {
            // console.log(response)
            if(!response.data.result||!response.data.result.ticket){
                this.setState({noResult:true});
                return;
            }
                
            this.props.setTicket({
                type:this.props.rootsActions.formsActions.ZENDESK_TICKET,
                params:
                    response.data.result
            });

            this.setState({noResult:false});
                
        })
        .catch((error) => {
          console.log(error.response)
        });
    }

    render() {
        
        if(this.props.ticket){
            return(<></>);
        }

        return (
            <>
           
                <Form className="" 
                    onSubmit={e => {e.preventDefault();this.searchTicket(e)}}
                    >
                    <FormGroup>
                        <legend>    
                            <FormattedMessage 
                                id={this.props.title}
                                defaultMessage="Formulaire"
                            />
                        </legend>
                        <Label>
                            <FormattedMessage 
                                id="PC.ticket-label"
                                defaultMessage="Inscrire le # de Ticket, puis attendre ou faire entrée"
                            />
                        </Label>
                        <Input 
                            type="text"
                            value={this.state.ticket_id}
                            onChange={this.idleChange}
                        />
                    </FormGroup>
                    {this.state.noResult &&
                        <legend style={{fontSize:'12px',color:'red'}}>Ce ticket ne fonctionne pas :(</legend>
                    }
                </Form>
                                
            </>
        );
    }
}


const mapActionsToProps = {
    setTicket:rootsActions.formsActions.setTicket
}

const mapStateToProps = state => ({
    rootsActions:rootsActions,
    forms:state.forms,
});

// export default injectIntl(connect(mapStateToProps)(Med));
export default injectIntl(connect(mapStateToProps,mapActionsToProps)(TicketSearch));