import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "reactstrap";

import PDF from './pdf'
//precise all special string fied here :)
//extra options in https://mozilla-services.github.io/react-jsonschema-form/

class PreviewModal extends React.Component {

  constructor(props){
        super(props);

        this.toggle =  this.props.toggle.bind(this);
        // this.modalValues =  this.modalValues.bind(this);

        this.state = {
            modal:this.props.isOpen,
            currentPiece:{
                name:this.props.name,
                url:this.props.url,
                type:this.props.type
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.currentPiece&&nextProps.currentPiece.name !== this.state.currentPiece.name) {
            this.setState({
                modal:this.props.isOpen,
                currentPiece:{
                    name:this.props.name,
                    url:this.props.url,
                    type:this.props.type
                }
            })
        }
    }
    
    render(){
        return(
            <Modal isOpen={this.props.isOpen} toggle={this.toggle} className={this.props.className}>
                <ModalHeader toggle={this.toggle}>{this.props.name}</ModalHeader>
                <ModalBody>
                {this.props.type === 'image' &&
                    <img 
                        alt={this.props.name}
                        src={this.props.url}
                        style={{
                            'display':'inline-block',
                            'margin':'0 auto',
                            'maxWidth':'450px'
                        }}
                    />
                }

                {this.props.type === 'pdf' &&
                    <PDF 
                    zoom={1.2}
                    display="block"
                    changed={false}
                    url={this.props.url}
                    ticket={this.props.name}
                    menu={{
                        before:{
                            preview:false
                        },
                        after:{
                            save:true,
                            tag:false,
                            send:false,
                            preview:false,
                        }
                    }}
                    />
                }

                {this.props.type !== 'pdf' && this.props.type !== 'image' &&
                    <a href={this.props.url} target="_blank">{this.props.name}</a>
                }
                </ModalBody>
                <ModalFooter>
                <Button color="secondary" onClick={this.toggle}>Retour</Button>
                </ModalFooter>
            </Modal>);
    }
}

export default PreviewModal; 