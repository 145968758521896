import _ from "lodash";
import React from 'react';
import { updateNamedImports } from "typescript";
import TextInput from "../../components/Utils/TextInput"


import {Input, FormGroup, Label,Row,Col} from 'reactstrap';
import Address from "../../components/Utils/Address"

const splitREXPX = /_\d_/;

const TEXT = 'text';


const SHARE= "share"
const SHARE_PRICE= "actionPrice"
const FISCAL_YEAR  = "date" ;
const SHARE_HOLDERS= "userForm"
const COMPANY_NAME  = "companyName" ;
const OTHER_NAME  = "otherName" ;
const RESTRICTION  = "restriction" ;
const FILE_LANG  = "fileLang" ;
const ADDRESS = "address";
const ADDRESS_STREET  = "address_street" ;
const ADDRESS_CITY  = "address_city" ;
const ADDRESS_POSTAL  = "address_postal" ;
const HQ = "hq";
const HQ_STREET  = "hq_street" ;
const HQ_CITY  = "hq_city" ;
const HQ_POSTAL = "hq_postal";
const HQ_COUNTRY  = "hq_country" ;
const HQ_STATE = "hq_state";
const POSTAL_ADDRESS = "postalAddress";
const AUDIT = "auditForm";
const ADMIN = "adminForm";
const EXCUTIVE = "executiveForm";
const LEADER = "leaderForm";
const DIGIT = "digit-company";
const NUMBER_OF_EMPLOYEES = 'employees';

export function genSchema(incorpo){

  let name= "",inner=false;
  incorpo = groupBy(incorpo);

  let arr = [];
  for( let i in incorpo){
    name = i;
    inner = !!(typeof incorpo[i] === 'object')

    if(name.match(splitREXPX))
      arr.push(defineType(name.split(splitREXPX)[0],incorpo[i]))
    else
      arr.push(defineType(name,incorpo[i]))
    // if(inner){
    //   defineType(name,incorpo[i])
    //   // for(let j in incorpo[i]){
    //   //   arr.push(defineType(j))
    //   // }
    // }
    // else{

    // }

  }

  return arr;
}

function defineType(name,obj){
  let id = "";

  switch (name){
    case NUMBER_OF_EMPLOYEES:
    case SHARE:
    case SHARE_PRICE:
    case FISCAL_YEAR:
    case COMPANY_NAME:
    case OTHER_NAME:
    case RESTRICTION:
    case HQ_STREET:
    case HQ_CITY:
    case HQ_POSTAL: 
    case TEXT:
      return(<TextInput key={name} name={name} label={name} default={obj}/>);
    case FILE_LANG:
    case DIGIT:
      return(<span key={name}>
        FUTUR DIGIT INPUT --->{ obj}
      </span>)
    case SHARE_HOLDERS:
      id = name + "_" + obj.id;
      return (<div key={obj.id}>
        <Row><Col> Actionnaire {obj.id}</Col></Row>
        <TextInput key={id+"_name"} name={id+"_name"} label={"prénom"} default={obj.firstname}/>
        <TextInput key={id+"_lastname"} name={id+"_lastname"} label={"nom"} default={obj.name}/>
        <Address name={id} col={12} default={obj}/>
      </div>);
    case AUDIT:
      id = name + "_" + obj.id;
      return (<div key={obj.id}>
        <Row><Col> Audit {obj.id}</Col></Row>
        <TextInput key={id+"_name"} name={id+"_name"} label={"prénom"} default={obj.firstname}/>
        <TextInput key={id+"_lastname"} name={id+"_lastname"} label={"nom"} default={obj.name}/>
        <Address name={id} col={12} default={obj}/>
        <TextInput key={id+"_bureau"} name={id+"_bureau"} label={"bureau"} default={obj.bureau}/>
        <TextInput key={id+"_job"} name={id+"_job"} label={"Profession"} default={obj.job}/>
      </div>);
    case ADMIN:
      id = name + "_" + obj.id;
      return (<div key={obj.id}>
        <Row><Col> Aministrateur  {obj.id}</Col></Row>
        <TextInput key={id+"_name"} name={id+"_name"} label={"prénom"} default={obj.firstname}/>
        <TextInput key={id+"_lastname"} name={id+"_lastname"} label={"nom"} default={obj.name}/>
        <TextInput key={id+"counsil-position"} name={id+"counsil-position"} label={"position au sein du conseil"} default={obj.name}/>
        <Address name={id} col={12} default={obj}/>
      </div>);
    case EXCUTIVE:
      id = name + "_" + obj.id;
      return (<div key={obj.id}>
        <Row><Col> Dirigeant  {obj.id}</Col></Row>
        <TextInput key={id+"_name"} name={id+"_name"} label={"prénom"} default={obj.firstname}/>
        <TextInput key={id+"_lastname"} name={id+"_lastname"} label={"nom"} default={obj.name}/>
        <TextInput key={id+"_fonction"} name={id+"_fonction"} label={"fonction"} default={obj.name}/>
        <Address name={id} col={12} default={obj}/>
      </div>);
    case HQ:
      return (<div key={HQ}>
        <Row><Col> Adresse siège social</Col></Row>
        <Address name={HQ} col={12} default={obj}/>
      </div>);
    case POSTAL_ADDRESS:
      return (<div key={POSTAL_ADDRESS}>
        <Row><Col> Adresse postale</Col></Row>
        <Address name={POSTAL_ADDRESS} col={12} default={obj}/>
      </div>);
    case ADDRESS:
      return (<div key={ADDRESS}>
        <Row><Col> Adresse</Col></Row>
        <Address name={ADDRESS} col={12} default={obj}/>
      </div>);
    default:
    return(<span key={name}> -----> {name} </span>);
  }
}

function groupBy(data){
  console.log(data);
  let obj = {};
  
  let name= "",type="",match;

  for(let d in data){
    type = match = null;

    if(d.match(splitREXPX)){
      //get number in string ;)
      match = d.match(splitREXPX)[0][1]
      name = d.split(splitREXPX)[0];
      type = d.split(splitREXPX)[1]
    }
    else
      name = d;

    if(d.match(/hq_/)){
      if(!obj.hq)
        obj.hq = {}

        obj.hq[d.split(/hq_/)[1]] = data[d]
    }
    else if (d.match(/postalAddress_/)){
      
      if(!obj.postalAddress)
        obj.postalAddress = {}

        obj.postalAddress[d.split(/postalAddress_/)[1]] = data[d]
    }
    else if (d.match(/address_/)){
      
      if(!obj.address)
        obj.address = {}

        obj.address[d.split(/address_/)[1]] = data[d];
    }
    else if(!type){
      obj[name] = data[d];
    }
    else{
      // if key doesnt exist
      if(!obj[name+"_"+match+"_"])
        obj[name+"_"+match+"_"] = {id:match};

      obj[name+"_"+match+"_"][type] = data[d];
    }
      
  }
  // data = _(data).keys().sort().each(function (key) {
  //   ordered[key] = data[key];
  // });
  console.log(obj)
  return obj;
}
// import React from "react";

const uiSchema={
    //evidence is the translation for piece à conviction bonne traduction
    ticket:{
      "ui:placeholder":"Penal.ticket-placeholder"
    },
    penalTicket:{
      "ui:placeholder":"Penal.penalTicket-placeholder"
    },
    fax:{
      "ui:placeholder":"Penal.fax-placeholder"
    },
    palaisjustice:{
      "ui:placeholder":"Penal.palaisjustice-placeholder",
      "ui:widget": "textarea",
    },
    poursuivant:{
      "ui:placeholder":"Penal.poursuivant-placeholder"
    },
    prenom:{
      "ui:placeholder":"Penal.prenom-placeholder"
    },
    nom:{
      "ui:placeholder":"Penal.nom-placeholder"
    },
    address:{
      "ui:placeholder":"Penal.address-placeholder"
    },
    date_envoi:{
      "ui:placeholder":"Penal.date_envoi-placeholder"
    },
    district:{
      "ui:placeholder":"Penal.district-placeholder"
    },
    ville_envoi:{
      "ui:placeholder":"Penal.ville_envoi-placeholder"
    },
  
    file_id:{
      "ui:placeholder":'Penal.ticket-placeholder',
    },
    //hidden
    source:{
      "ui:widget":"hidden",
      "default": ""
    },
    language:{
      "ui:widget":"hidden",
      "default": ""
    },
    lawyer_zendesk:{
      "ui:widget":"hidden",
      "default": ""
    },
    id_users:{
      "ui:widget":"hidden",
      "default": ""
    },
    id:{
      "ui:widget":"hidden",
      "default": ""
    },
    dossiername:{
      "ui:widget":"hidden",
      "default": ""
    },
    dossier:{
      "ui:widget":"hidden",
      "default": ""
    },
    guid:{
      "ui:widget":"hidden",
      "default": ""
    },
    id_zendesk:{
      "ui:widget":"hidden",
      "default": ""
    },
  }

  export default uiSchema;