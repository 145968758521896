import React from "react";
import {
    Badge,
    ListGroup,
    ListGroupItem,
    Button,
} from "reactstrap";

import { mimeType } from '../../helper';

const QueueList = function (PDF) {
    let arr = [];
    for (let p in PDF) {
        arr.push(
            <ListGroupItem key={p} id={p}
            >
                {PDF[p].ticket + ': ' + PDF[p].type}

                <Badge
                    color={(PDF[p].status === 'COMPLETE') ? 'success' : 'info'}
                    className="float-right"><div>{(PDF[p].status === 'COMPLETE') ? 'Complet' : 'En cours'}</div></Badge>
                {(PDF[p].status === 'COMPLETE') ?
                    <Badge
                        color="danger"
                        className="float-right"
                        onClick={(e) => {
                            this.edit({ ticket: PDF[p].ticket })
                        }}>Modifier</Badge> : ''
                }
                {(PDF[p].status === 'COMPLETE') ?
                    <Badge
                        color="warning"
                        className="float-right"
                        onClick={(e) => {
                            this.queuePreview({ type: 'pdf', url: PDF[p].url, name: PDF[p].ticket,ticket: PDF[p].ticket, ticketVisible:true })
                        }}>Voir</Badge> : ''}

            </ListGroupItem>)
    }
    return <ListGroup>{arr}</ListGroup>;
}
export default QueueList;