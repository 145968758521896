import React from "react";
import {
    Badge,
    ListGroup,
    ListGroupItem,
  } from "reactstrap";

import {mimeType} from '../../helper';

const DisplayPieces = function(pieces){
    let arr = [];
    for(let p in pieces){
        let type = false;
        if(pieces[p].type){
        type = mimeType(pieces[p].type);
        }
        arr.push(
        <ListGroupItem key={p} id={p} onClick={(e) => {this.modalValues(e.currentTarget.id)}}>
            {pieces[p].name}<Badge 
                color={(type==='image')?'info':(type==='pdf')?'danger':'warning'} 
                className="float-right">{type}</Badge>
        </ListGroupItem>)
    }
    return <ListGroup>{arr}</ListGroup>;
}
export default DisplayPieces;