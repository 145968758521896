import React from "react";

const CustomCheckbox = function(props) {
    return (
      <>
      <br/>
      <label className="switch">
        <input 
          id={props.id}
          className="form-control"
          type="checkbox"
          value={props.value}
          onChange={(event) => props.onChange(!!event.target.checked)}
        />
        <span className="slider round"></span>
      </label>
      </>
    );
  };


export default CustomCheckbox;

