import {privateAxios as axios} from "../../utils/axios"

// bind with this
function submitFormComponent(data,path,saveId){
    if(!path){
        console.log('no path in submitformcomponent !!')
        return false
    }
        
    if(!this.props){
        console.log('forgot to bind with this')
        return false
    }

    if(saveId){
      
      this.props.queuePDF({
        type:saveId,
        data:data,
        status:'INCOMPLETE',
        url:''
      });
    }

    // this.props.showSpinner();

    let p = this.props;

    this.props = {...p,...data};

    data.AUTH_ID = localStorage.getItem('user_id');

    axios.post(
      path,
      data,{
        timeout:0,
      }
    )
    .then((response) => {
      // this.props.hideSpinner();
      this.props.genPDF({
        type:this.props.rootsActions.formsActions.GEN_PDF_PREVIEW,
        params:{
          ticket:data.ticket,
          exceptions:JSON.parse(response.data.result).exceptions,
          url:'data:application/pdf;base64,'+(JSON.parse(response.data.result).pdf),
          changed:false
        },
      });

    })
    .catch((error) => {
      this.props.hideSpinner();
    });
    
    for(p in this.schema.properties){
      this.schema.properties[p].default = data[p]
    }

    this.props.init();
    // this.setState()
}

export default submitFormComponent;