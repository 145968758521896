import React, {Component} from 'react';
import {
    Form, FormGroup, Button, Input, Container , Label, Row,Col, FormText,
    InputGroup, InputGroupAddon, InputGroupText,
} from 'reactstrap';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailBulk,faUserTie, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import Postal from './Postal';

const mail = <FontAwesomeIcon icon={faMailBulk} />;

export default class Address extends Component{
    constructor(props){
        super(props);

        
    }

    render(){

        console.log('worse than ebola',this.props)
        return(
            <>
            <Row>
                <Col md={this.props.col||6}>
                    <FormGroup tag={"fieldset"}>
                        {this.props.label?<Label>{this.props.label}</Label>:''}
                        {this.props.text?<FormText>{this.props.text}</FormText>:''}
                        <Row> 
                            <Col md={12}>
                                <FormGroup >
                                    <Label>Rue</Label>
                                    <Input
                                        type="text"
                                        name={this.props.name+'_street'}
                                        placeholder={"Rue"}
                                        defaultValue={this.props.default["street"]||''}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row> 
                            <Col md={6}>
                                <FormGroup >
                                    <Label>ville</Label>
                                    <Input
                                        type="text"
                                        name={this.props.name+'_city'}
                                        placeholder={"Ville"}
                                        defaultValue={this.props.default["city"]||''}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6"  >
                                <FormGroup >
                                    <Label>Code postal</Label>
                                    <Postal
                                        name={this.props.name+'_postal'}
                                        defaultValue={this.props.default["postal"]||''}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        
                        <Row> 
                            
                            <Col md={6}>
                                <FormGroup >
                                    <Label>Province/État</Label>
                                    <Input
                                        type="text"
                                        defaultValue={this.props.default["state"]||''}
                                        // defaultValue="Québec"
                                        name={this.props.name+'_state'}
                                        placeholder={"Québec"}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6"  >
                                <FormGroup >
                                    <Label>Pays</Label>
                                    <Input
                                        type="text"
                                        name={this.props.name+'_country'}
                                        defaultValue={this.props.default["country"]||''}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
                </Row>
            </>
        )
    }
}
