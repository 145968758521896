import React, {Component} from 'react';

import {Input, FormGroup, Label,Row,Col} from 'reactstrap';

export default class TextInput extends Component{
    constructor(props){
        super(props);

    }


    render(){
        return <>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Label>{this.props.label}</Label>
                        <Input name={this.props.name} defaultValue={this.props.default}  />
                    </FormGroup>
                </Col>
            </Row>
        </>;
    }
}