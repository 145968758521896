import React from "react";
import {injectIntl,FormattedMessage} from 'react-intl';
import Form from 'react-jsonschema-form'

import {privateAxios as axios} from "../utils/axios"
import { connect } from 'react-redux';

import rootsActions from '../_actions/root-actions';

import uiSchema from './ui_schema/penal_ui';
import schema from './schema/penal_schema';
import submitFormComponent from './customComponents/submitFormComponent';

import {
  ButtonGroup,
  Button
} from "reactstrap";

import {
  mimeType,
  dealWithTrad,
  getInitialState
} from '../helper';
//precise all special string fied here :)
//extra options in https://mozilla-services.github.io/react-jsonschema-form/


class Attachement_Penal extends React.Component {

  constructor(props){
    super(props);

    const { intl } = this.props;

    this.state = {
      'ticket_id':this.props.forms.ticket['ticket-id']
    }

    this.schema = schema(this.props);

    this.setTicket = this.setTicket.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = submitFormComponent.bind(this);
    this.dealWithTrad = dealWithTrad.bind(this); 

    for(let u in uiSchema)
      for(let v in uiSchema[u])
        if(v === "ui:placeholder" && uiSchema[u][v].match(/Penal\./))
          uiSchema[u][v]=intl.formatMessage({id:uiSchema[u][v]})

    this.state = this.dealWithTrad(this.schema);
    this.getInitialState = getInitialState.bind(this); 
    this.form = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.ticket.ticket !== this.state.properties.ticket.default){
      this.setTicket();
    }
  }


  setTicket(){
    // const state = { ...this.state }
    // const currentState = state;
    // const ticket = {
    //   "default": ''+this.props.forms.ticket.ticket,
    //   "type":"string",
    //   "title":'Penal.ticket',
    // };

    // console.log('warui',ticket)
    // currentState.properties.ticket = ticket;
    // this.setState(currentState);
    // this.forceUpdate();
  }

  getInitialState(e){
    this.setState({});
    this.props.init();
  } 

  onChange(e){
    // console.log(e)
  }

  render(){

    if(!this.props.ticket){
      return(<></>)
    }

    // this.setTicket();
    return(
      <div className="form-padding">
        <legend>
          <FormattedMessage 
            id={'PC.title'}
            defaultMessage={'Afficher'}>
          </FormattedMessage>
          <ButtonGroup className="float-right">
              <Button 
                  color="info"
                  size="sm"
                  onClick={this.getInitialState} 
              >
                Réinitialiser
              </Button>
          </ButtonGroup>
        </legend>
        <sub>
          <FormattedMessage 
            id={'PC.description'}
            defaultMessage={'Afficher'}>
          </FormattedMessage>
        </sub>
        <Form 
          idPrefix={"PC_form"}
          schema={this.state}
          uiSchema={uiSchema}
          onChange={this.onChange}
          onSubmit={({formData},e) => {e.preventDefault();this.onSubmit(formData,'/penal/preview')}}
          // onError={log("errors")} 
        >
          <div>
              <Button
                  type="submit"
                  color="success"
                  size="lg" block
              >
                  <FormattedMessage 
                      id={'PC.submit'}
                      defaultMessage={'Afficher'}>
                  </FormattedMessage>
              </Button>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rootsActions:rootsActions,
  forms:state.forms,
  showSpinner:rootsActions.spinnerActions.display,
  hideSpinner:rootsActions.spinnerActions.hide,
});

const mapActionsToProps = {
  genPDF:rootsActions.formsActions.genPDF,
  init:rootsActions.formsActions.init,
}

export default injectIntl(connect(mapStateToProps,mapActionsToProps)(Attachement_Penal,{forwardRef:true}));