const fr = {
    "neolegal.url":"https://www.neolegal.ca/fr",
    
    'no':'non',
    'yes':'oui',
    'PC.manual-mode':'Mode manuel',
    'login.remember-me':'Se souvenir de moi!',
    "login.greet":"",
    "login.home":"allo",
    "login.other-lang":"en",
    "login.undesired-visit":'Client? Suivez ce lien pour visiter le site de vente.',
    'login.email':'Courriel',
    'login.password':'Mot de passe',
    'login.login':'Connexion',
    'login.error-email':'Remplissez cet espace avec un courriel valide s\'il vous plaît!',
    'login.error-password':'Fournissez votre mot de passe s\'il vous plaît!',

    'route:MED':'Mise en demeure',
    'route:PC':'Petites créances',
    'route:Penal':'Penal',

    'fields.email':'courriel',
    'fields.password':'mot de passe',
    //intlreact me rend la vie penible.... besoin de cette valeur pour calmer la console
    'fields.logout.logout':'tu ne dois pas voir ça en théorie',

    'sentence.conjonction':' et',

    'validation.fields':'les champs erronnés sont:',
    'validation.title':'Ces champs comportents des erreurs, vérifiez-les s\'il vous plaît',

    'error.oups':'Cette URL est erronée, il s\'agit peut-être d\'une erreur.',
    'error.philosopher':'韓非子 - Han Fei Zi',

    'sidebar.other-lang':'Change language',
    'sidebar.logout':'Déconnexion',
    'sidebar.hello':'HALLO',

    'logout.expiration':'Votre session a expiré',
    'logout.logout':'Déconnexion complétée avec brio!',

    //AttachmentPC
    'PC.submit':'Visualiser',

    'PC.title':'Formulaire PC',
    'PC.description':'Formulaire petite créances',
    
    'PC.object':'Objet de la missive',
    'PC.ticket':'Ticket Zendesk',
    'PC.ticket-placeholder':'#00001',
    'PC.complainant':'Notre client',
    'PC.complainant-placeholder':'Mr. Neolegal',
    'PC.complainant-email':'Courriel du demandeur',
    'PC.complainant-email-placeholder':'plaignant@gmail.com',
    'PC.defendant':'La partie adverse',
    'PC.defendant-placeholder':'Mr.Defendeur',
    'PC.defendant-email':'Courriel du defendeur',
    'PC.defendant-email-placeholder':'defendeur@gmail.com',
    'PC.pieces':'Pièces',
    'PC.pieces-placeholder':'Document incriminant\r\nPhoto Incriminante\r\nAutographe de Céline',
    'PC.pj':'Pièces jointes',
    'PC.ticket-label':'Inscrire le # de Ticket, puis attendre ou faire entrée',

    'PC.listTitle':'Titre des listes',
    'PC.district':'District',
    'PC.district-placeholder':'Kuujjuaq',
    'PC.area':'Localité',
    'PC.area-placeholder':'Palais de justice de kuujjuaq\n\r12345 rue des inuits',
    'PC.file_id':'# de fichier',
    'PC.label':'Formulaire PC',
    'PC.which-side':'Type de client',
    'PC.textBody':"Texte à l'attention du greffier",
    'Penal.label':'Formulaire Penal',
    'Penal.ticket-label':'Inscrire le # de Ticket, puis attendre ou faire entrée',

    'Penal.ticket':'Ticket zendesk',
    'Penal.penalTicket':'# de constat',
    'Penal.fax':'Télécopieur',
    'Penal.palaisjustice':'Palais de justice',
    'Penal.poursuivant':'Poursuivant (ville)',
    'Penal.prenom':'Prénom',
    'Penal.nom':'Nom',
    'Penal.address':'Adresse',
    'Penal.date_envoi':'Date d\'envoi',
    'Penal.ville_envoi':'Ville d\'envoi',
    'Penal.file_id':'Numéro de dossier à la cour',

    'Penal.ticket-placeholder':'12345',
    'Penal.penalTicket-placeholder':'#123-456-7898',
    'Penal.fax-placeholder':'514-586-8989',
    'Penal.palaisjustice-placeholder':'Palais de justice de Kuujjuaq',
    'Penal.poursuivant-placeholder':'Montréal',
    'Penal.prenom-placeholder':'Pierre',
    'Penal.nom-placeholder':'Lemoyne d\'Iberville',
    'Penal.address-placeholder':'address',
    'Penal.date_envoi-placeholder':'07/22/2019',
    'Penal.ville_envoi-placeholder':'Montréal',
    'Penal.file_id-placeholder':'0000000',
    'Penal.district-placeholder':'Abitibi'
}

export default fr;