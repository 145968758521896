import React from "react";
import ReactDOM from "react-dom";

import {Provider} from 'react-redux';
import App from './App'
import {
  usersReducer,
  customersReducer,
  formsReducer,
  spinnerReducer
} from './_reducers/root-reducer';

import {combineReducers,createStore} from 'redux';

const reducers = combineReducers({
   users:usersReducer,
   forms:formsReducer,
   spinner:spinnerReducer
});

const store = createStore(reducers,{
  spinner:false,
  users:{
    "status": true,
    "result": {
        "email": localStorage.getItem('email'),
        "token": localStorage.getItem('token')
    }
  },
  forms:{
    PDF_REQUEST:false,
    pdf:"",
    ticket:{
      ticket:''
    },
    PDF_QUEUE:[]
  }
});

window.store = store;

ReactDOM.render(
    <Provider store={store}>
      <div style={{height:'auto'}}>
        <App></App>
      </div>
    </Provider>,
  document.getElementById("root")
);