import * as customersActions from './customers-actions';
import * as usersActions from './users-actions';
import * as formsActions from './forms-actions';
import * as spinnerActions from './spinner-actions';

export default { 
    customersActions,
    usersActions,
    formsActions,
    spinnerActions,
}