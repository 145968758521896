import { 
  GEN_PDF_PREVIEW,
  ZENDESK_TICKET,
  ZENDESK_TICKET_INIT,
  ZENDESK_PDF_REQUEST,
  PDF_CHANGED,
  PDF_QUEUE_PUSH
} from '../_actions/forms-actions';
import { isDebuggerStatement } from 'typescript';

export default function formsReducer(state = '',{type,params}){

  switch(type){
    case PDF_QUEUE_PUSH:
      let PDF_QUEUE =  state.PDF_QUEUE;
      editId = PDF_QUEUE.findIndex((e)=> e.ticket == params.ticket);

      if(editId > -1){
        PDF_QUEUE[editId] = params;
        PDF_QUEUE[editId].status = 'INCOMPLETE';
      }
      else{
        PDF_QUEUE.push(params);
      }

      
      return {
        
        PDF_QUEUE:PDF_QUEUE,
        ...state
      }


    case ZENDESK_PDF_REQUEST:
      if(document.getElementById('neolegalForm')){
        document
          .getElementById('neolegalForm')
          .dispatchEvent(new Event('submit', { cancelable: true }))
      }
      return {
        ...state,
        PDF_REQUEST:true
      };
    case ZENDESK_TICKET_INIT:
      localStorage.removeItem('PC_save');
        return {
          pdf:'',
          ticket:{

          },
          reset:true,
          PDF_QUEUE:state.PDF_QUEUE
        };

    case GEN_PDF_PREVIEW:
      let queue = state.PDF_QUEUE,
        editId = queue.findIndex((e)=> e.ticket == params.ticket);

      if(editId > -1){
        queue[editId].url = params.url;
        queue[editId].status = 'COMPLETE';
      }
      
      return{
        ...state,
        pdf:params.ticket,
        pdf:params.url,
        exceptions:params.exceptions,
        PDF_REQUEST:false,
        changed:params.changed,
        PDF_QUEUE:queue
      }

    case PDF_CHANGED:
      return{
        ...state,
        changed:true,
        PDF_REQUEST:false
      }

    case ZENDESK_TICKET:
        return{
          ...state,
          pdf:'',
          ticket:params
        }
    default:
      return state;
  }
}