// import React from "react";

const uiSchema={
    //evidence is the translation for piece à conviction bonne traduction
    ticket:{
      "ui:placeholder":"Penal.ticket-placeholder"
    },
    penalTicket:{
      "ui:placeholder":"Penal.penalTicket-placeholder"
    },
    fax:{
      "ui:placeholder":"Penal.fax-placeholder"
    },
    palaisjustice:{
      "ui:placeholder":"Penal.palaisjustice-placeholder",
      "ui:widget": "textarea",
    },
    poursuivant:{
      "ui:placeholder":"Penal.poursuivant-placeholder"
    },
    prenom:{
      "ui:placeholder":"Penal.prenom-placeholder"
    },
    nom:{
      "ui:placeholder":"Penal.nom-placeholder"
    },
    address:{
      "ui:placeholder":"Penal.address-placeholder"
    },
    date_envoi:{
      "ui:placeholder":"Penal.date_envoi-placeholder"
    },
    district:{
      "ui:placeholder":"Penal.district-placeholder"
    },
    ville_envoi:{
      "ui:placeholder":"Penal.ville_envoi-placeholder"
    },
  
    file_id:{
      "ui:placeholder":'Penal.ticket-placeholder',
    },
    //hidden
    source:{
      "ui:widget":"hidden",
      "default": ""
    },
    language:{
      "ui:widget":"hidden",
      "default": ""
    },
    lawyer_zendesk:{
      "ui:widget":"hidden",
      "default": ""
    },
    id_users:{
      "ui:widget":"hidden",
      "default": ""
    },
    id:{
      "ui:widget":"hidden",
      "default": ""
    },
    dossiername:{
      "ui:widget":"hidden",
      "default": ""
    },
    dossier:{
      "ui:widget":"hidden",
      "default": ""
    },
    guid:{
      "ui:widget":"hidden",
      "default": ""
    },
    id_zendesk:{
      "ui:widget":"hidden",
      "default": ""
    },
  }

  export default uiSchema;