import React from "react";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "routes.js";
import {
    Container,
    Row,
    Col
} from "reactstrap";

class Settings extends React.Component {

//wow argon wow
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  render() {
    return (
      <>
        <Sidebar
        //large or mini
          size="mini"
          changeLang={this.props.changeLang}
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/",
            imgSrc: require("assets/img/brand/favicon.png"),
            imgAlt: "..."
          }}
        />

        <div className="main-content" ref="mainContent">
            <AdminNavbar
                {...this.props}
                brandText={(localStorage.getItem('lang')==='fr')?'Paramètres':'Settings'}
            />
            <Container>
                <Row>
                    <Col sm="12" md="5" lg="5" xl="5" className="">
{/* eslint-disable-next-line */}
                        <marquee>DANCE OF THE MARQUEEDIVI</marquee>
                    </Col>
                </Row>
            </Container>
          {/* pas besoin de routes pour une */}
          {/* <Switch>{this.getRoutes(routes)}</Switch> */}
          {/* <Container fluid>juste e</Container> */}
        </div>
      </>
    );
  }

// maybe useful at somepoint

//   getRoutes = routes => {
//     return routes.map((prop, key) => {
//       if (prop.layout === "/admin") {
//         return (
//           <Route
//             path={prop.layout + prop.path}
//             component={prop.component}
//             key={key}
//           />
//         );
//       } else {
//         return <Redirect key={'key to please react'} to='/auth/login'  />;
//       }
//     });
//   };

//   getBrandText = path => {
//     for (let i = 0; i < routes.length; i++) {
//       if (
//         this.props.location.pathname.indexOf(
//           routes[i].layout + routes[i].path
//         ) !== -1
//       ) {
//         return routes[i].name;
//       }
//     }
//     return "Brand";
//   };
}

export default Settings;
