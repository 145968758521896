/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";


import {FormattedMessage, injectIntl} from 'react-intl';

import {privateAxios as axios} from "../../utils/axios"

// reactstrap components
import {
  // Button,
  // Card,
  // CardHeader,
  // CardBody,
  // CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  // Progress,
  // Table,
  Container,
  Row,
  Col
} from "reactstrap";

var ps;

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
    size:'large'
  };
  constructor(props) {
    super(props);

    // console.log(this.props,this);
    this.state.size=this.props.size||'large'

    // this.setState({'size':'lol'})
    this.changeLang = this.props.changeLang.bind(this);

    // console.log(this.changeLang);
    this.activeRoute.bind(this);
  }

  logout(){
    localStorage.clear();
    sessionStorage.setItem('error','logout.logout')
    window.location.reload();
  }

  hello(){

    axios.get(
      '/hello',{
      params:{
        email:'jeremie.morales@neolegal.ca'
      }
    })
    .then((response) => {
      console.log('generic success');
    })

  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = routes => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            href={`${process.env.PUBLIC_URL}`+prop.path}
            tag="a"
            onClick={this.closeCollapse}
          >
            <i className={prop.icon} />

            <span className={(this.state.size=='mini')?'d-md-none d-sm-block d-xs-block':''}>
              {prop.name}
            </span>
            
          </NavLink>
        </NavItem>
      );
    });
  };
  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank"
      };
    }
    return (
      <Navbar
        className={"overflow-x-allow navbar-vertical fixed-left navbar-light bg-white "+this.state.size}
        expand="md"
        id="sidenav-main"
      >
        <Container fluid className="text-xs-left text-sm-left text-md-center text-lg-center">
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className="pt-0" {...navbarBrandProps} href="/">
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}
          {/* User */}
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={require("assets/img/theme/default-user.jpg")}
                    />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Contenu à venir!</h6>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            {/* <Form className="mt-4 mb-3 d-md-none">
              <InputGroup className="input-group-rounded input-group-merge">
                <Input
                  aria-label="Search"
                  className="form-control-rounded form-control-prepended"
                  placeholder="Search"
                  type="search"
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <span className="fa fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Form> */}
            {/* Navigation */}
            <Nav navbar className='overflow-x-allow'>
              <NavItem key="med">
                <NavLink
                  href="/main"
                  tag="a"
                  onClick={this.closeCollapse}
                >
                  <i className="far fa-file-alt" />

                  <span className={(this.state.size=='mini')?'d-md-none d-sm-block d-xs-block':''}>
                    MED
                  </span>
                </NavLink>
              </NavItem>
              <NavItem key="penal">
                <NavLink
                  href="/penal"
                  tag="a"
                  onClick={this.closeCollapse}
                >
                  <i className="fas fa-gavel" />

                  <span className={(this.state.size=='mini')?'d-md-none d-sm-block d-xs-block':''}>
                    Penal
                  </span>
                </NavLink>
              </NavItem>
              <NavItem key="testament">
                <NavLink
                  href="/doc/testament"
                  tag="a"
                  onClick={this.closeCollapse}
                >
                  <i className="fas fa-scroll" />

                  <span className={(this.state.size=='mini')?'d-md-none d-sm-block d-xs-block':''}>
                    Testament
                  </span>
                </NavLink>
              </NavItem>
              {this.createLinks(routes)}
            </Nav>
            {/* Divider */}
            <hr className="my-3 overflow-x-allow" />
            {/* Heading */}
            {/* <h6 className="navbar-heading text-muted">Settings</h6> */}
            {/* Navigation */}
            <Nav className="mb-md-3 overflow-x-allow" navbar>

              <NavItem>
                <NavLink
                  title='logout'
                  tag="a"
                  href="/logout" 
                  // onClick={(e) => {e.preventDefault;this.logout()} }
                >
                  <i className="ni ni-key-25 text-info" />
                  <span className={(this.state.size=='mini')?'d-md-none d-sm-block d-xs-block':''}>
                    <FormattedMessage 
                        id={'sidebar.logout'}
                        defaultMessage={'Déconnexion'}>
                    </FormattedMessage>
                  </span>
                  
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink title='language' onClick={(e) => {e.preventDefault;this.changeLang()} }>
                  <i className="ni ni-world" />
                  <span className={(this.state.size=='mini')?'d-md-none d-sm-block d-xs-block':''}>
                    <FormattedMessage 
                        id={'sidebar.other-lang'}
                        defaultMessage={'Changer de langue'}>
                    </FormattedMessage>
                  </span>
                  
                </NavLink>
              </NavItem>
                  
              {/* <NavItem>
                <NavLink title='balsze' onClick={(e) => {e.preventDefault;this.hello()} }>
                  <i className="ni ni-diamond" />
                  <span className={(this.state.size=='mini')?'d-md-none d-sm-block d-xs-block':''}>
                    <FormattedMessage 
                        id={'sidebar.hello'}
                        defaultMessage={'HELLO'}>
                    </FormattedMessage>
                  </span>
                  
                </NavLink>
              </NavItem> */}

              {/* <NavItem>
                <NavLink title='settings' href="https://demos.creative-tim.com/argon-dashboard-react/documentation/alerts?ref=adr-admin-sidebar">
                  <i className="ni ni-settings-gear-65" />
                  <span className={(this.state.size=='mini')?'d-md-none d-sm-block d-xs-block':''}>
                    cristal
                  </span>
                </NavLink>
              </NavItem> */}
                  
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}]
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};

export default Sidebar;
