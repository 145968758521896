import React from "react";
import {FormattedMessage, injectIntl} from 'react-intl';

class ErrorList extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }

  render() {
        
        const { intl } = this.props;
        let template = <div></div>,
            errors = '';
        
        for(let e in this.props.errors)
            errors+= intl.formatMessage({id:'fields.'+this.props.errors[e]}) + ', ';
            
        if(!!errors){

            errors = errors.substring(0, errors.length - 2);
            let str = intl.formatMessage({id:'sentence.conjonction'});
            errors = errors.replace(/,(?!.*,)/,str);
        }
        
        if(!!this.props.errors&&!!Object.keys(this.props.errors).length&&!this.props.logoutError){
            template = (
                <div className="error-list" >
                    <h5>
                        <FormattedMessage 
                        id={'validation.title'}
                        defaultMessage={'Ces champs comportents des erreurs, vérifiez-les s\'il vous plaît'}>
                        </FormattedMessage>
                    </h5>
                    
                    <span className="capitalize">
                        <FormattedMessage 
                            id={'validation.fields'}
                            defaultMessage={'les champs erronnés sont:'}>
                        </FormattedMessage> {errors}
                    </span>
                </div>
            )
        }

        else if(!!this.props.errors&&this.props.logoutError){
            template = (
                <div className="error-list" >
                    <h5>
                        <FormattedMessage 
                            id={this.props.errors[0]}
                            defaultMessage={'Erreur de connexion'}>
                        </FormattedMessage>
                    </h5>
                </div>
            )
        }

        return (template)
  }
}

export default injectIntl(ErrorList);