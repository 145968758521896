import React from "react";

const uiSchema = {
    "pieces_list":{
    },

    
    "pj":{
      "type":"string",
      "ui:placeholder":"p.j Liste des pièces et pièces P-X à P-Y"
    },

    "textBody":{
      "ui:widget":"textarea",
      "ui:options": {
        "rows": 8
      },
      "ui:help":"Madame,Monsieur est déjà inclu. {pc_index_main} se convertit en P-X à P-Y"
    },
  
    "manual":{
      "ui:widget":"CheckboxWidget",
      "ui:help":"Si jamais vous n'avez pas besoin d'imprimer les pièces..."
    },
  
    "district":{
      "ui:placeholder":"PC.district-placeholder",
      "ui:options": {
        "help":"false"
      }
    },
    "area":{
      "ui:placeholder":"PC.area-placeholder",
      "ui:widget":"textarea",
      "ui:options": {
        "rows": 4
      }
    },
    "file_id":{
      "ui:placeholder":"PC.ticket-placeholder",
    },
  
    "pieces":{
      "ui:widget": "textarea",
      "ui:options": {
        "rows": 4
      },
      "ui:placeholder": "PC.pieces-placeholder",
      "ui:help":<div>- «D-99» si on veut commencer à la pièce 99. <br/> - «a_» à la fin de la ligne pour amender une pièce. <br/> - «b_» à la fin de la ligne pour ignorer l'endos dans PDF</div>,
    },
    "ticket":{
      "ui:placeholder":"PC.ticket-placeholder",
      "ui:readonly":true,
    },
    "complainant":{
      "ui:placeholder":"PC.complainant-placeholder",
      "ui:widget":"textarea",
      "ui:options": {
        "rows": 4
      },
      "ui:help":"(nom,adresse,téléphone,courriel)"
      
    },

    "defendant":{
      "ui:placeholder":"PC.defendant-placeholder",
      "ui:widget":"textarea",
      "ui:options": {
        "rows": 4,
        "resize":"vertical"
      },
      "ui:help":"(nom,adresse,téléphone,courriel)"
    },

    "source":{
      "ui:widget":"hidden"
    },
  
    "attachments":{
      "ui:widget":"hidden"
    },
  
    "language":{
      "ui:widget":"hidden",
      "default": ""
    },
    "lawyer_zendesk":{
      "ui:widget":"hidden",
      "default": ""
    },
    "id_users":{
      "ui:widget":"hidden",
      "default": ""
    },
    "id":{
      "ui:widget":"hidden",
      "default": ""
    },
    "dossiername":{
      "ui:widget":"hidden",
      "default": ""
    },
    "dossier":{
      "ui:widget":"hidden",
      "default": ""
    },
    "guid":{
      "ui:widget":"hidden",
      "default": ""
    },
    "id_zendesk":{
      "ui:widget":"hidden",
      "default": ""
    },
  }

export default uiSchema;