const mimes = require('./utils/mimes.json');

//this function is not optimized, have to talk to sid abot this one....
export function mimeType(mime){
    if(mime&& typeof mime === 'string'){
        for(let m in mimes){
            if(typeof mimes[m] === 'string')
                if(mimes[m]=== mime)
                    return m;
                
                for(let n in mimes[m]){
                    if(mimes[m][n] === mime)
                        return m;
                }
        }
    }
    else
        return '';
}

//bind with this
//if linked to a redux init fuction use it, if you just want
// to reset the state, please use this.setState
export function getInitialState(){
    this.setState({});
    if(this.props&&this.props.init)
        this.props.init();
} 

// if type of field can be translated
const splitAtFirstDot = /\./;
export function deserveTrad(key,obj){
    return(
      obj[key] &&
      obj[key].match&&
      obj[key].match(splitAtFirstDot)&&
      key!=='description'&&
      key!=='url'&&
      key!=='filename'&&
      key!=='name'&&
      key!=='default'
    )
}

//if it has already been translated
export function isTradObject(parent,obj){
    return (
        obj && 
        !parent.$$typeof &&(
        Array.isArray(obj)||typeof obj === 'object'
    ));
}

// must bind with this see attachment_pc
export function dealWithTrad(object){
    
    const { intl } = this.props;

    if(typeof object === 'string')
        return intl.formatMessage({id:object});

    if(!intl)
        console.log('eh? no intl? in deal with trad')
    
    for(let o in object){
        // eslint-disable-next-line
        // to bypass jsx stuff $$typeof
        if(isTradObject(object,object[o])){
        object[o] = this.dealWithTrad(object[o]);
        }
        else{
        if(deserveTrad(o,object))
            object[o] = intl.formatMessage({id:object[o]})
        }
    }

    return object
}


