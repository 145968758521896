import axios from "axios";

//non authenticaded fonction like login and logout

const PUBLIC_TIMEOUT = 10000;
const PRIVATE_TIMEOUT = 10000;

const isHandlerEnabled = () => {
    return (process.env.REACT_APP_ALLOW_LOG&&process.env.REACT_APP_ALLOW_LOG!=='false')
}

const successHandler = (response) => {
    return response
}

const errorHandler = (error) => {
    if (isHandlerEnabled()) {
        console.log('Erreur provenant du serveur ->',{ ...error })
    }
    return { status:error.response.status };
}

const privateErrorHandler = (error) => {
    if (isHandlerEnabled()) {
        console.log('Erreur provenant du serveur ->',{ ...error })
    }
    if(error.response.status===401){
        localStorage.clear();
        window.location.href=`${process.env.PUBLIC_URL}/logout`;

        return;
    }

    return { status:error.response.status };
}

const publicAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout:PUBLIC_TIMEOUT,
    headers:{
        'Content-Type': 'application/json',
    },
    auth:{
        username:'admin',
        password:'6d21b4833368d61c31275092886a4d9add8f7801abd7892d017b3f19a37b30c8'
      },
})

const privateAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 
        'Content-Type': 'application/json',
        'x-jwt-token':localStorage.getItem('token')
    },
    auth:{
        username:'admin',
        password:'6d21b4833368d61c31275092886a4d9add8f7801abd7892d017b3f19a37b30c8'
    },
    timeout:PRIVATE_TIMEOUT
})

publicAxios.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
)

privateAxios.interceptors.response.use(
    response => successHandler(response),
    error => privateErrorHandler(error)
)

export {
    publicAxios,
    privateAxios
}