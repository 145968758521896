import React from "react";

import rootsActions from '../../_actions/root-actions';
import { connect } from 'react-redux';
import {
    Container,
    Row,
    Col
} from "reactstrap";
// core components

import {injectIntl} from 'react-intl';
import JSONForm from '../../Forms/attachment_PC'
import PDF from '../../components/Utils/pdf'
import TicketSearch from '../../components/Utils/ticket-search';
import QueueList from '../../Forms/customComponents/queueList';
import PreviewModal from '../../components/Utils/previewModal';

class PC extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            ticket:'',
            save:false,        
            currentPiece:{name:'',url:'',type:''},
            modal:false
        }

        if(!!localStorage.getItem('PC_save')){
            let save = JSON.parse(localStorage.getItem('PC_save'));
            this.state.ticket = save.ticket;
            this.state.save = save; 
            // console.log(this.props.forms.ticket)
        }
        // this.form = React.createRef();
        
        this.QueueList = QueueList.bind(this);
        this.toggle =  this.toggle.bind(this);
        this.back = this.back.bind(this)
    }   

    toggle() {
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
    }

    edit({ ticket }){
        let state = this.props.forms.PDF_QUEUE.find((e) => e.ticket == ticket );

        if(state){
            
            this.props.setTicket({
                type:this.props.rootsActions.formsActions.ZENDESK_TICKET,
                params:
                    state.data
            });
        }
        
    }
    
    queuePreview(obj){
        this.setState({
            currentPiece:obj
        },this.toggle);
    }

    back(){
        this.setState({
            ticket:'',
            save:false,        
            currentPiece:{name:'',url:'',type:''},
            modal:false
        },this.props.init)
    }
    
    render() {
        const props = this.props;
        return (
            <>
                <Container className="giga-max-width">
                    <Row className="weirdScroll">
                        <Col sm="12" md="5" lg="5" xl="5" className="weirdScroll">
                            {/* <section className=""> */}
                            <section className="form-height">
                                <TicketSearch
                                    source="APC"
                                    title="PC.label"
                                    search={this.state.pdfPreview} 
                                    ticket={props.forms.ticket.ticket||(!props.forms.reset&&this.state.save.ticket)}
                                />
                                {(props.forms.ticket&& props.forms.ticket.ticket)&&
                                    <JSONForm 
                                        {...props.forms.ticket}
                                        save={!!this.state.save}
                                        ref={externalSubmit => this.externalSubmit = externalSubmit}
                                    />
                                }
                                {/* is saved data */}
                                {(this.state.save&&!props.forms.reset)&&
                                    <JSONForm 
                                        {...this.state.save}
                                        save={!!this.state.save}
                                        ref={externalSubmit => this.externalSubmit = externalSubmit}
                                    />
                                }
                            </section>
                            <section>
                                {(props.forms.ticket && !props.forms.ticket.ticket)?this.QueueList(this.props.forms.PDF_QUEUE):''}
                            </section>
                        </Col>
                        <Col 
                            id="scrollarea"
                            className="weirdScroll"
                            xs="12" sm="12" md="7" lg="7" xl="7"
                        >
                            <PDF 
                                zoom={2}
                                changed={props.forms.changed}
                                // ticket={props.forms.ticket.ticket||this.state.save.ticket}
                                // url={(props.forms)?props.forms.pdf:''}
                                back={this.back}
                                menu={{
                                    before:{
                                        // preview:(this.state.ticket)?true:false,
                                    },
                                    after:{
                                        // preview:true,
                                        save:true,
                                        tag:false,
                                        send:false,
                                        back:true,
                                    }
                                }}
                                {...this.state.currentPiece}
                                />
                        </Col>
                    </Row>
                </Container>
                {/* <PreviewModal
                    toggle={this.toggle}
                    isOpen={this.state.modal}
                    beforeToggle={() => {return true;}}
                    {...this.state.currentPiece}
                    /> */}
            </>
        );
    }
}

const mapActionsToProps = {
    init:rootsActions.formsActions.init,
    setTicket:rootsActions.formsActions.setTicket
}

const mapStateToProps = state => ({
    rootsActions:rootsActions,
    forms:state.forms,
});

export default injectIntl(connect(mapStateToProps,mapActionsToProps)(PC));

///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////

/* DO NOT DELETE, TO IMPLEMENT SOMEWHERE ELSE */
/* <hr/> */
/* https://www.npmjs.com/package/react-step-wizard */
/* <StepWizar
    onStepChange={this.onStepChange}
>
    <First/>
    <Second/>
</StepWizard> */
// class First extends React.Component {
//     render(){
//         return (
//             <>
//             <h2>text</h2>
//             <ReactQuill value={'ff'}
//                   onChange={this.handleChange} />
//             <Button 
//                 type="button"
//                 onClick={this.props.previousStep}
//                 color="primary">Previous Step</Button>
//             <Button 
//                 type="button"
//                 onClick={this.props.nextStep}
//                 color="primary">Next Step</Button></>
//         )
//     }
// }


// class Second extends React.Component {
//     render(){
//         return (
//             <>
//             <h2>text 2</h2>
//             <ReactQuill value={'gg'}
//                   onChange={this.handleChange} />
//             <Button 
//                 type="button"
//                 onClick={this.props.previousStep}
//                 color="primary"
//             >Previous Step</Button>
//             <Button 
//                 type="button"
//                 onClick={this.props.nextStep}
//                 color="primary"
//             >Next Step</Button></>)
//     }
// }

