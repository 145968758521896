import React from "react";

import { css } from '@emotion/core';
import {ScaleLoader,PacmanLoader} from 'react-spinners';
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
// import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "routes.js";

import rootsActions from '../_actions/root-actions';
import { connect } from 'react-redux';

import Index from "views/Index.jsx";
import PC from "views/ls_template/PC.jsx";
import Penal from "views/ls_template/Penal.jsx";

import Incorporation from "views/ls_template/Incorporation.jsx";

import Profile from "views/ls_template/Profile.jsx";
import Icons from "views/ls_template/Icons.jsx";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Admin extends React.Component {

  constructor(props){
    super(props);

    this.state={
      loading:true
    }
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname === routes[i].path) {
        return routes[i].name;
      }
    }
    return "Accueil";
  };
  
  render() {
    return (
      <>
        <Sidebar
        //large or mini
          size="mini"
          changeLang={this.props.changeLang}
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/",
            imgSrc: require("assets/img/brand/favicon.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />

          {this.props.view==='Index'&&
            <Index />
          }

          {this.props.view==='PC'&&
            <PC/>
          }
          
          {this.props.view==='Incorporation'&&
            // <div>monkey paw</div>
            <Incorporation/>
          }

          {this.props.view==='Penal'&&
            <Penal/>
          }

          {this.props.view==='Profile'&&
            <Profile/>
          }

          {this.props.view==='Icons'&&
            <Icons/>
          }

          <div className={(this.props.spinner)?'sweet-loading':'d-none'}>
            {/* <ScaleLoader

              css={override}
              sizeUnit={"px"}
              width={5}
              height={100}
              color={'#0052BB'}
              loading={false}
            /> */}
            <PacmanLoader
                css={override}
                sizeUnit={"px"}
                size={32}
                height={100}
                color={'#0052BB'}
                loading={this.props.spinner}
            />
          </div> 
        </div>
      </>
    );
  }
}

const mapActionsToProps = {
  showSpinner:rootsActions.spinnerActions.display,
  hideSpinner:rootsActions.spinnerActions.hideSpinner
}

const mapStateToProps = state => ({
  spinner:state.spinner,
});

export default connect(mapStateToProps,mapActionsToProps)(Admin);