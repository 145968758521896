import React from "react";
import { Row, Col, Container } from "reactstrap";

import { IntlProvider, FormattedMessage} from 'react-intl'
import i18n from '../i18n/loader';

const messages = i18n;
class Error extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
          locale: localStorage.getItem('lang')||'fr'
        };
    
    }

    componentDidUpdate(e) {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.mainContent.scrollTop = 0;
    }

  render() {
    return (
        <IntlProvider locale={this.state.locale} messages={messages[this.state.locale]}>
            <div className="main-content error">
                <div className="header bg-gradient-info py-5 py-lg-5">  
                    <Container>
                        <div className="header-body text-center mb-7">
                            <img src={require('assets/img/brand/neolegal_big_reverse.png')} width="300px;" alt="Neolegal"></img>
                            <Row className="justify-content-center">
                                <Col lg="6" md="6">
                                <h2 className="text-white">
                                    «<FormattedMessage id={'error.oups'} defaultMessage={'404 not found'}></FormattedMessage>»
                                </h2>
                                <em className="text-white philosopher" ><FormattedMessage id={'error.philosopher'} defaultMessage={'Han Fei'}></FormattedMessage></em>
                                </Col>
                            </Row>
                        </div>
                    </Container>   
                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                            className="fill-default"
                            points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                    {/* <Container className="mt--8 pb-5">
                        <Row className="justify-content-center">
                        </Row>
                    </Container> */}
                </div>
            </div>
        </ IntlProvider>
    );
  }
}

export default Error;
