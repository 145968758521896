import { 
    DISPLAY_SPINNER,
    HIDE_SPINNER
  } from '../_actions/spinner-actions';
  
  
export default function spinnerReducer(state = '',{type}){

    switch(type){
  
        case DISPLAY_SPINNER:
            return true;
        case HIDE_SPINNER:
            return false;
        default:
            return state;
    }
  }