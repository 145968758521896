import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

class Header extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-gradient-info pb-3 pt-3 pt-md-3">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="12" xl="12">
                  <Row className="d-flex justify-content-center">
                    <h1 
                    className="pt-5 pb-1 display-1 text-white ubuntu"
                      // className={(localStorage.getItem('user_id')==='38')?"pt-5 pb-1 display-1 text-white lobster":"pt-5 pb-1 display-1 text-white ubuntu"} 
                    >
                        Bonjour{localStorage.getItem('firstName')?' '+localStorage.getItem('firstName')+'!':'!'}
                      </h1>
                    <h6> Que peut-on faire pour toi? </h6>
                    {/* <Jumbotron>
                      <h2 className="display-3"> Bienvenue Azalée Cyr!</h2>
                      <p className="lead">Je t'invite à choisir l'outil de ton choix.</p>
                      <hr className="my-2" />
                      <p>Tu ne désires plus voir cette page par défaut?</p>
                      <p className="lead">
                        <Button href="/settings" color="primary">Clique ici!</Button>
                      </p>
                    </Jumbotron> */}
                  </Row>
                </Col>
                <Col lg="6" xl="3">
                  <Card tag="a" href={`${process.env.PUBLIC_URL}/pc`} className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      {/* outil MED ni ni-paper-diploma */}
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-muted mb-0"
                          >
                            Petites créances?
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            Outil PC
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                            <i className="fa fa-paperclip" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 0
                        </span>{" "}
                        <span className="text-nowrap">de liés à ton compte</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card tag="a" href="/penal" className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-muted mb-0"
                          >
                            Constat D'infraction?
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            Outil penal
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-gavel" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 0
                        </span>{" "}
                        <span className="text-nowrap">liés à ton compte!</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card tag="a" href="/main" className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-muted mb-0"
                          >
                            Mise en demeure?
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            Outil MED
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="far fa-file-alt" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 0
                        </span>{" "}
                        <span className="text-nowrap">liés à ton compte!</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card tag="a" href="/doc/testament" className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-muted mb-0"
                          >
                            Testament
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            Outil Testament
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fa fa-scroll" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 0
                        </span>{" "}
                        <span className="text-nowrap">liés à ton compte!</span>
                      </p>
                    </CardBody>
                  </Card>
                {/* <Col lg="6" xl="3">
                  <Card tag="a" href="/incorporation" className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-muted mb-0"
                          >
                            Incorporation
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            Outil Incorpo
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fa fa-money" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 0
                        </span>{" "}
                        <span className="text-nowrap">liés à ton compte!</span>
                      </p>
                    </CardBody>
                  </Card> */}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
