//PLEASE,DO NOT INCLUDE ANY AUTH PATH 

import Index from "views/Index.jsx";
import PC from "views/ls_template/PC.jsx";
import Penal from "views/ls_template/Penal.jsx";

// import Profile from "views/ls_template/Profile.jsx";
// import Maps from "views/ls_template/Maps.jsx";
// import Register from "views/ls_template/Register.jsx";
// import Tables from "views/ls_template/Tables.jsx";
// import Icons from "views/ls_template/Icons.jsx";
import langs from  "i18n/loader"

function i18n_format(str){
  
  let l = (localStorage.getItem('lang')||'fr');
  return langs[l][str]|| 'lost in translation';
}

var routes = [
  {
    path: "/",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/pc",
    name: i18n_format('route:PC'),
    icon: "fa fa-paperclip",
    component: PC,
    layout: "/admin"
  },
  {
    path: "/incorporation",
    name: "Incorporation",
    icon: "fas fa-gavel",
    component: Penal,
    layout: "/admin"
  },
  
  // {
  //   path: "/logout",
  //   name: "Logout",
  //   icon: "ni ni-settings-gear-65 text-primary",
  //   component: Login,
  //   layout: "/login"
  // },
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Index,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin"
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin"
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   icon: "ni ni-key-25 text-info",
  //   component: Login,
  //   layout: "/auth"
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth"
  // }

];

export default routes;
