
function schema(attr,attach,pieces_list){

    var fields = {
        // "title":'PC.title',
        // "description":'PC.description',
        // // "description":'PC.description',
        "type": "object",
        "required": [
            'ticket'
        ],
        "properties": {
            //hidden fields
            "source":{
            "type":"string",
            "title":"source",
            "default":attr["source"]
            },
            "language":{
            "type":"string",
            "title":"language",
            "default":attr["language"]
            },
            "lawyer_zendesk":{
            "type":"string",
            "title":"lawyer_zendesk",
            "default":attr["lawyer_zendesk"]
            },
            "id_users":{
                
            "type":"string",
            "title":"id_users",
            "default":attr["id_users"]
            },
            "id":{
            "type":"string",
            "title":"id",
            "default":attr["id"]
            },
            "dossiername":{
            "type":"string",
            "title":"dossiername",
            "default":attr["dossiername"]
            },
            "dossier":{
            "type":"string",
            "title":"dossier",
            "default":attr["dossier"]
            },
            "guid":{
            "type":"string",
            "title":"guid",
            "default":attr["guid"]
            },
            "id_zendesk":{
            "type":"string",
            "title":"id_zendesk",
            "default":attr["id_zendesk"]
            },
            // visibles ones
            "ticket":{
            "type":"string",
            "title":'Penal.ticket',
            "default":attr['ticket']
            },
            "penalTicket":{
            "type":"string",
            "title":'Penal.penalTicket',
            "default":attr['penalTicket']
            },
            "fax":{
            "type":"string",
            "title":'Penal.fax',
            "default":attr['fax']
            },
            "palaisjustice":{
            "type":"string",
            "title":'Penal.palaisjustice',
            "default":attr['palaisjustice']
            },
            "poursuivant":{
            "type":"string",
            "title":'Penal.poursuivant',
            "default":attr['poursuivant']
            },
            "prenom":{
            "type":"string",
            "title":'Penal.prenom',
            "default":attr['prenom']
            },
            "nom":{
            "type":"string",
            "title":'Penal.nom',
            "default":attr['nom']
            },
            "address":{
            "type":"string",
            "title":'Penal.address',
            "default":attr['address']
            },
            "date_envoi": {
            "type": "string",
            "title": 'Penal.date_envoi',
            "default":attr[ 'date_envoi']
            },
            "ville_envoi":{
            "type":"string",
            "title":"Penal.ville_envoi",
            "default":attr["ville_envoi"]
            }
        }
    };

    return fields;
};

export default schema;