import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

import defaultUser from '../../assets/img/theme/default-user.jpg';

const animalArray = ['🐧','🐶','🐱','🐭','🐹','🐰','🦊','🐻','🐼','🐨','🐯','🦁','🐮','🐷','🐸','🐵','🙈','🙉','🐔','🐦','🐤','🐣','🦖','🦎','🐠','🐟','🐬','🍄','🍑','🍉']
const index = Math.floor(Math.random()*Math.floor(30));
class AdminNavbar extends React.Component {

  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark high-z-index" expand="md" id="navbar-main" >
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {this.props.brandText}
            </Link>
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center" style={{"padding":"0 15px"}}>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {localStorage.getItem('firstName')?localStorage.getItem('firstName')+' ':'Utilisateur '}
                      </span>
                    </Media>
                    <span className="avatar avatar-sm rounded-circle">
                      {/* <img
                        alt="..."
                        src={defaultUser}
                      /> */}
                    </span>
                  </Media>
                </DropdownToggle>
                {/* <DropdownMenu className="dropdown-menu-arrow" right> */}
                  {/* <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0 text-center">
                      {animalArray[index] }
                        Coucou!  
                      {animalArray[index] }
                    </h6>
                  </DropdownItem> */}
                  {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Settings</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Activity</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Support</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem> */}
                {/* </DropdownMenu> */}
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
