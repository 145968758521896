const en = {
    "neolegal.url":"https://www.neolegal.ca/en",

    'no':'no',
    'yes':'yes',
    'PC.manual-mode':'manual mode',
    'login.remember-me':'Remember me!',
    "login.home":"hello",
    'login.other-lang':"fr",
    "login.greet":"Good morning!",
    "login.undesired-visit":'Are you a customer? Please follow this link!',
    'login.email':'Email',
    'login.password':'Password',
    'login.login':'Login',
    'login.error-email':'This address isn\'t in a valid format, please fix it.',
    'login.error-password':'Please provide a password.',

    'route:MED':'Formal notice',
    'route:Penal':'Penal',
    'route:PC':'Smal Claims (PC)',

    'fields.email':'email',
    'fields.password':'password',

    //intlreact me rend la vie penible.... besoin de cette valeur pour calmer la console
    'fields.logout.logout':'tu ne dois pas voir ça en théorie',

    'sentence.conjonction':' and',

    'validation.fields':'Fields that contain at least one error are:',
    'validation.title':'These fields contains some errors, please take a look.',

    'error.oups':'This URL is invalid, something might be wrong',
    'error.philosopher':'韓非子 - Han Fei Zi',

    'sidebar.other-lang':'Changer de langue',
    'sidebar.logout':'Logout',

    'sidebar.hello':'Hello',

    'logout.expiration':'your session has expired',
    'logout.logout':'Logout success',

    //AttachmentPC
    'PC.submit':'Preview',

    'PC.title':'PC Form',
    'PC.description':'Small claim form',
    'PC.pj':'Joint files',
    'PC.listTitle':'List title',
    'PC.object':'Subject',
    'PC.ticket':'Zendesk ticket ',
    'PC.ticket-placeholder':'#00002',
    'PC.complainant':'Our client (name,address,phone,email)',
    'PC.complainant-placeholder':'Mr. Neolegal',
    'PC.complainant-email':'Complainant email',
    'PC.complainant-email-placeholder':'complainant@gmail.com',
    'PC.defendant':'The opposite side (name,address,phone,email)',
    'PC.defendant-placeholder':'Mr. Defendant',
    'PC.defendant-email':'Defendant email',
    'PC.defendant-email-placeholder':'defendant@gmail.com',
    'PC.pieces':'pieces',
    'PC.pieces-placeholder':'Document about that crime\r\nPicture about the same crime',
    'PC.textBody':'bailiff text',
    'PC.ticket-label':'Please type the ticket ID then wait or enter submit',

    
    'PC.district':'District',
    'PC.area':'Area',
    'PC.file_id':'File number',

    'PC.label':'Formulaire PC',
    'Penal.label':'Formulaire Penal',
    'Penal.ticket-label':'Please insert the ticket number then wait',

    'Penal.ticket':'Zendesk ticket',
    'Penal.penalTicket':'Penal Ticket',
    'Penal.fax':'Fax',
    'Penal.palaisjustice':'courthouse',
    'Penal.poursuivant':'Pursuer (city)',
    'Penal.prenom':'Firstname',
    'Penal.nom':'Lastname',
    'Penal.address':'Address',
    'Penal.date_envoi':'Sent date',
    'Penal.ville_envoi':'From which town',
    'Penal.file_id':'Folder ID',

    'Penal.ticket-placeholder':'12345',
    'Penal.penalTicket-placeholder':'#123-456-7898',
    'Penal.fax-placeholder':'514-586-8989',
    'Penal.palaisjustice-placeholder':'Palais de justice de Kuujjuaq',
    'Penal.poursuivant-placeholder':'Montreal',
    'Penal.prenom-placeholder':'Pierre',
    'Penal.nom-placeholder':'Lemoyne d\'Iberville',
    'Penal.address-placeholder':'address',
    'Penal.date_envoi-placeholder':'07/22/2019',
    'Penal.ville_envoi-placeholder':'Montreal',
    'Penal.file_id-placeholder':'0000000',
    'Penal.district-placeholder':'Abitibi',
}

export default en;