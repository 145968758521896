import React from "react";

import { connect } from 'react-redux';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col
} from "reactstrap";

import {FormattedMessage, injectIntl} from 'react-intl';

import {publicAxios} from "../../utils/axios";
// import base64 from 'base-64';
////https://availity.github.io/availity-reactstrap-validation/components/avform/
import { AvForm, AvInput, AvFeedback, AvGroup } from 'availity-reactstrap-validation';
import ErrorList from '../../components/Utils/error-list';

import rootsActions from '../../_actions/root-actions';

class Login extends React.Component {

  constructor(props){
    super(props);

    localStorage.clear();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    if(sessionStorage.getItem('error')){
      this.state = {
        errors:[sessionStorage.getItem('error')],
        logoutError:true
      };  
    }
    else{
      this.state = {
        errors:[]
      };  
    }
  }

  handleChange(event) {
    let obj = {};

    obj[event.target.name]= event.target.value;

    this.setState(obj);
  }

  handleSubmit(event, errors, values) {

    if(errors&& errors.length){
      this.setState({errors:errors})
    }

    publicAxios.post(
      '/login',{
        'email':this.email.value,
        'password':this.password.value,
    })
    .then((response) => {
      if(response.status>200)
        return;

      this.props.userUpdate(
        {
          type:this.props.rootsActions.LOGIN,
          params:response.data,
          status:response.status
        }
      );
    })

    sessionStorage.setItem('error','');
  }

  render() {
    const { intl } = this.props;
    return (
      <>
        <Col lg="5" md="7" >
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-4 py-lg-4">
              <div className="text-center text-muted mb-4">
                <small>
                  <a href={intl.formatMessage({id:"neolegal.url"})} >
                    <FormattedMessage 
                      id={'login.undesired-visit'}
                      defaultMessage={'Client? Suivez ce lien pour visiter le site de vente.'}>
                    </FormattedMessage>
                  </a>
                </small>
              </div>
              <AvForm 
                role="form" 
                onSubmit={this.handleSubmit}
              >
                <AvGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <AvInput
                      name="email"
                      ref={(f) => this.email = f }
                      placeholder={intl.formatMessage({id:'login.email'})}
                      type="email" 
                      onChange={this.handleChange}
                      required
                    />
                    <AvFeedback>
                      <FormattedMessage 
                        id={'login.error-email'}
                        defaultMessage={'Remplissez cet espace avec un courriel valide s\'il vous plaît!'}>
                      </FormattedMessage>
                    </AvFeedback>
                  </InputGroup>
                </AvGroup>
                <AvGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <AvInput 
                      name="password"
                      ref={(f) => this.password = f }
                      placeholder={intl.formatMessage({id:'login.password'})}
                      type="password"
                      onChange={this.handleChange}
                      required
                    />
                  <AvFeedback>
                    <FormattedMessage 
                        id={'login.error-password'}
                        defaultMessage={'Remplissez votre mot de passe s\'il vous plaît!'}>
                    </FormattedMessage>
                  </AvFeedback>
                  </InputGroup>
                </AvGroup>
                {/* <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">
                      <FormattedMessage 
                        id={'login.remember-me'}
                        defaultMessage={'Se souvenir de moi!'}>
                      </FormattedMessage>
                    </span>
                  </label>
                </div> */}
                <div className="text-center">
                  <Button 
                    className="my-4"
                    color="primary"
                    type="submit"
                  >    
                    <FormattedMessage 
                      id={'login.login'}
                      defaultMessage={'Connexion'}>
                    </FormattedMessage>
                  </Button>
                </div>
              </AvForm>
              <ErrorList errors={this.state.errors} logoutError={this.state.logoutError}>
              </ErrorList>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}
//not state, but need it to access the name space :)
const mapStateToProps = state => ({
  rootsActions:rootsActions,
  customers:state.customers,
  users:state.users,
});

const mapActionsToProps = {
  userUpdate:rootsActions.usersActions.onLogin
}

export default injectIntl(connect(mapStateToProps,mapActionsToProps)(Login));