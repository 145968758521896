import React from 'react';

import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import { connect } from 'react-redux';

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import ErrorLayout from "layouts/Error.jsx";
import SettingsLayout from "layouts/Settings.jsx";


//i18n
import {addLocaleData} from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';

// import auth  from './API/auth';
// import {LOGIN,onLogin}  from './actions/auth' 
import PrivateRoute from 'react-private-route';
// import actions from './_actions/root-actions';



import i18n from './i18n/loader';

import { IntlProvider} from 'react-intl';

addLocaleData(fr);
addLocaleData(en);

const messages = i18n;

class App extends React.Component{
  constructor(props) {
    super(props);
    
    this.state = {
      locale: localStorage.getItem('lang')||'fr'
    };

    this.changeLang = this.changeLang.bind(this);
    this.selectRoute = this.selectRoute.bind(this)
  }

  changeLang(){
    if(this.state.locale === 'fr')
      this.setState({'locale':'en'});
    else
      this.setState({'locale':'fr'});
  
    localStorage.setItem('lang',this.state.locale);
  };

  isAuthenticated(){
    // if the token is present, access admin interface, even if someone hard code it,
    // all the calls will return errors and he will see an empty layout before returning 
    return !!(localStorage.getItem('token')&&localStorage.getItem('email'));
  }

  selectRoute(){
    // #TODO fix redirection
    if(!!this.isAuthenticated()){
// pc
// penal
// icons
// user-profile
      return  (
      <>
        {/* <Redirect exact from="/" to="/index" /> */}
        <PrivateRoute 
          path={`${process.env.PUBLIC_URL}/Penal`}
          component={
            props => <AdminLayout
            changeLang={this.changeLang} 
            {...props} />} 
            isAuthenticated={!!this.isAuthenticated()}
            view="Penal" 
        />

        <PrivateRoute 
          path={`${process.env.PUBLIC_URL}/PC`}
          component={
            props => <AdminLayout
            changeLang={this.changeLang} 
            {...props} />} 
            isAuthenticated={!!this.isAuthenticated()}
            view="PC" 
        />
        
        <PrivateRoute 
          path={`${process.env.PUBLIC_URL}/incorporation`}
          component={
            props => <AdminLayout
            changeLang={this.changeLang} 
            {...props} />} 
            isAuthenticated={!!this.isAuthenticated()}
            view="Incorporation" 
        />

        <PrivateRoute 
          path={`${process.env.PUBLIC_URL}/index`}
          component={
            props => <AdminLayout
            changeLang={this.changeLang} 
            {...props} />} 
            isAuthenticated={!!this.isAuthenticated()}
            view="Index" 
        />
        <PrivateRoute 
          path={`${process.env.PUBLIC_URL}/settings`}
          component={
            props => <SettingsLayout
            changeLang={this.changeLang} 
            {...props} />} 
            isAuthenticated={!!this.isAuthenticated()} 
        />
        
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/404`}
          component={
            props => <ErrorLayout 
            changeLang={this.changeLang}
            {...props} />   
          }
        />
        <Route exact path={`${process.env.PUBLIC_URL}/`}
         render={() => (
          !!this.isAuthenticated() ? (
            <Redirect to={`${process.env.PUBLIC_URL}/index`}/>
          ) : (
            <Redirect to={`${process.env.PUBLIC_URL}/index`}/>
          )
        )}/>
        <Route exact path={`${process.env.PUBLIC_URL}/login`}
         render={() => (
          !!this.isAuthenticated() ? (
            <Redirect to={`${process.env.PUBLIC_URL}/index`}/>
          ) : (
            <Redirect to={`${process.env.PUBLIC_URL}/login`}/>
          )
        )}/>    
      </>)
    }
    else{
      // console.log(`${process.env.PUBLIC_URL}/login`)
      return(
        <>
          <PrivateRoute 
            path={`${process.env.PUBLIC_URL}/login`}
            component={
              props => <AuthLayout
                changeLang={this.changeLang} 
                view="Login"
                {...props}
                />
            } 
              isAuthenticated={!this.isAuthenticated()} 
          />
          
          <Redirect exact from={`${process.env.PUBLIC_URL}/*`} to={`${process.env.PUBLIC_URL}/login`} />
        </>
      )
    }
  }

  render(){
    return (
       <IntlProvider locale={this.state.locale} messages={messages[this.state.locale]}>
        <BrowserRouter >
          <Switch>
            {this.selectRoute()}
          </Switch>
        </BrowserRouter>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
    customers:state.customers,
    users:state.users,
});

export default connect(mapStateToProps)(App);
