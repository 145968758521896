import React from "react";
import {injectIntl,FormattedMessage} from 'react-intl';
import Form from 'react-jsonschema-form'

import { connect } from 'react-redux';

import rootsActions from '../_actions/root-actions';
import uiSchema from './ui_schema/attachment_PC_ui';
import schema from './schema/attachment_PC_schema';
import {
  ButtonGroup,
  Button,
} from "reactstrap";

import PreviewModal from '../components/Utils/previewModal';
import CustomCheckbox from './customComponents/customCheckBox';
import DisplayPieces from './customComponents/displayPieces';
import errorComponent from './customComponents/errorComponent';
import lawyerCommentComponent from './customComponents/lawyerCommentComponent';
import submitFormComponent from './customComponents/submitFormComponent';


import {
  mimeType,
  dealWithTrad,
  getInitialState
} from '../helper';
import { isJSDocAllType } from "typescript";

class Attachment_PC extends React.Component {

  constructor(props){

    super(props);

    const { intl } = this.props;

    this.state = {
      'ticket_id':this.props.forms.ticket['ticket-id'],
      save:{}
    }
    this.timer=0;
    this.loading = false;
    //bypass state and string problem sorry.....
    this.attachments = (typeof this.props.attachments !== 'string')? this.props.attachments:JSON.parse(this.props.attachments);

    this.displayPieces = DisplayPieces.bind(this);
    

    this.schema = schema(this.props, this.attachments,{
      "type": "null",
      "title":'Liste des pièces',
      "default":null,
      "description":<>{this.displayPieces(this.attachments)}</>,
    });

    this.onSubmit = submitFormComponent.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.toggle =  this.toggle.bind(this);
    this.modalValues =  this.modalValues.bind(this);
    this.externalSubmit = this.externalSubmit.bind(this);
    
    this.dealWithTrad = dealWithTrad.bind(this); 
    //easier like this
    let rgx = /PC\./
      for(let u in uiSchema)
        for(let v in uiSchema[u])
          if(v === "ui:placeholder" && uiSchema[u][v].match(rgx))
            uiSchema[u][v]=intl.formatMessage({id:uiSchema[u][v]})

    this.state= {
      ...this.state,
      ...this.dealWithTrad(this.schema)
    } 
    this.state.modal = false;
    this.state.currentPiece = {name:'',url:'',type:''} ;
    this.getInitialState = getInitialState.bind(this); 
    this.form = React.createRef();

    this.state.warui = false;;
  }


  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  modalValues(e){
    this.setState({
      currentPiece:this.attachments[e]
    },this.toggle);
  }

  // convert into helper one day  ^^
  onChange (e) {
    clearTimeout(this.timer)
    this.timer = setTimeout(()=>{
        let p = this.props;
        this.props = {...p,...e.formData}
        let obj = {};
        let schemaCopy = {...this.state.properties};

        for(p in schemaCopy){
          this.schema.properties[p].default = obj[p] = this.props[p];
        }
        localStorage.setItem('PC_save',JSON.stringify(obj));
      },50);
  }

  onFocus(e){
    this.props.PDFChanged({
      type:this.props.rootsActions.formsActions.PDF_CHANGED,
      params:true,
    });
  }
  
  externalSubmit(){
    this.form.current.submit();
  }
 

  render(){

    if(!this.props.ticket){
      return(<></>);
    }

    let sch = this.schema
    let comment = "Aujourd’hui le printemps s’est mêlé à l’hiver Tout fond L’hiver n’a pas dit son dernier mot Un ancien imite le vent   Il m’a envoûtée Avec des ailes de perdrix Puis a disparu Tu m’amènes dans un sentier Tu écris dans le vent J’avance derrière toi J’observe le crayon qui dessine Ta liberté",
        lawyer = "Morales-Chaurette";
        
    return(
      <div className="form-padding">
        {/* { temp &&
          lawyerCommentComponent(comment,lawyer)
        } */}
        { this.props.forms.exceptions &&
          errorComponent(this.props.forms.exceptions,'Ces pièces ne sont pas imprimables, veuillez les télécharger, puis les gérer manuellement.')
        }
        <legend>
          <FormattedMessage 
            id={'PC.title'}
            defaultMessage={'Afficher'}>
          </FormattedMessage>
          <ButtonGroup className="float-right">
              <Button 
                  color="info"
                  size="sm"
                  onClick={this.getInitialState} 
              >
                Réinitialiser
              </Button>
              {/* <Button 
                  color="warning"
                  size="sm"
                  onClick={this.getInitialState} 
              >
                Retour
              </Button> */}
          </ButtonGroup>
        </legend>
        <sub>
          <FormattedMessage 
            id={'PC.description'}
            defaultMessage={'Afficher'}>
          </FormattedMessage>
        </sub>
        <Form 
          ref={this.form}
          id="neolegalForm"
          idPrefix={"PC_form"}
          schema={this.state}
          uiSchema={{...uiSchema}}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onSubmit={({formData},e) => {e.preventDefault();this.onSubmit(formData,'/pcattach','PC')}}
          noValidate={true}
          widgets={{CheckboxWidget: CustomCheckbox}}
      >
        <div>
              <Button
                  type="submit"
                  color="success"
                  size="lg" block
              >
                  <FormattedMessage 
                      id={'PC.submit'}
                      defaultMessage={'Afficher'}>
                  </FormattedMessage>
              </Button>
          </div>
        </Form>
        <PreviewModal
          toggle={this.toggle}
          isOpen={this.state.modal}
          beforeToggle={() => {return true;}}
          name={this.state.currentPiece.name}
          url={this.state.currentPiece.url}
          type={mimeType(this.state.currentPiece.type)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rootsActions:rootsActions,
  forms:state.forms,
  spinner:state.spinner,
});

const mapActionsToProps = {
  queuePDF:rootsActions.formsActions.queuePDF,
  PDFChanged:rootsActions.formsActions.PDFChanged,
  genPDF:rootsActions.formsActions.genPDF,
  showSpinner:rootsActions.spinnerActions.display,
  hideSpinner:rootsActions.spinnerActions.hide,
  init:rootsActions.formsActions.init,
}

export default injectIntl(connect(mapStateToProps,mapActionsToProps)(Attachment_PC,{forwardRef:true}));