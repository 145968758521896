import React from "react";

import rootsActions from '../../_actions/root-actions';
import { connect } from 'react-redux';
import {
    Container,
    Row,
    Col
} from "reactstrap";
// core components

import {injectIntl} from 'react-intl';
import JSONForm from '../../Forms/incorporation'
import PDF from '../../components/Utils/pdf'
import TicketSearch from '../../components/Utils/ticket-search';

class Incorporation extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            //reactjs work around ;_;
            zoomCSS:{},
            zoom:0.65,

            _timeout_id:-1,
            _view:{
                ticket:true,
                form:false,
                "PDF-preview":false,
            },
            numPages: null,
            pageNumber: 1,

            ticket:'',
            text:'',
            text2:'',
            ticket_id:''
        }

    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    render() {
        const view = this.state._view;
        const props = this.props;

        return (
            <>
                <Container>
                    <Row>
                        <Col sm="12" md="5" lg="5" xl="5" className="">
                            <section className="form-height">
                                {/* <TicketSearch
                                    title="Penal.label"
                                    search={this.state.pdfPreview} 
                                    ticket={props.forms.ticket.ticket}
                                /> */}
                                {/* {props.forms.ticket&& props.forms.ticket.ticket&& */}
                                    <JSONForm 
                                        {...props.forms.ticket}
                                    />
                                {/* } */}
 
                            </section>
                        </Col>
                        <Col 
                            id="scrollarea"
                            sm="12" md="7" lg="7" xl="7"
                            className={view.form?"content-top letter-format":"content-top letter-format"}
                        >
                            <PDF 
                                url={(props.forms)?props.forms.pdf:''}
                                menu={{
                                    before:{
                                        tag:true,
                                        preview:true
                                    },
                                   after:{
                                        preview:true,
                                        tag:true,
                                        save:true,
                                        send:true
                                    }
                                }}/>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

const mapActionsToProps = {
    setTicket:rootsActions.formsActions.setTicket
}

const mapStateToProps = state => ({
    rootsActions:rootsActions,
    forms:state.forms,
});

export default injectIntl(connect(mapStateToProps,mapActionsToProps)(Incorporation));
