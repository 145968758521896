import React from "react";
function schema(attr,attach,pieces_list){
  
    var fields = {
        // "title":'PC.title',
        // "description":'PC.description',
        // // "description":'PC.description',
        "type": "object",
        "required": [
          'ticket',
          'object',
          'complainant',
          'defendant',
          'textBody',
          'client_type',
          'district',
          'area',
          'file_id'
        ],
  
        "properties": {
  
          "ticket":{
            "type":"string",
            "title":'PC.ticket',
            "default":attr['ticket']||''
          },
  
          "object":{
            "type":"string",
            "title":'PC.object',
            "default":"Pièces"
          },
  
          "pj":{
            "type":"string",
            "title":'PC.pj',
            "default":""
          },

          "complainant":{
            "type":"string",
            "title":'PC.complainant',
            // field name to discuss, in case of save will fail
            "default":attr['signataires']||attr['complainant'],
          },
          // "complainant_email":{
          //   "type":"string",
          //   "title":'PC.complainant-email',
          //   "default":attr['complainant-email']
          // },
          "defendant":{
            "type":"string",
            "title":'PC.defendant',
            "rows":"10",
            // field name to discuss, in case of save will fail
            "default":attr['partieadverse']||attr['defendant']||''
          },
          // "defendant_email":{
          //   "type":"string",
          //   "title":'PC.defendant-email',
          //   "default":attr['defendant-email']||''
          // },
          "textBody":{
            "type":"string",
            "title":"PC.textBody",
            "default":attr['textBody']||''
          },
  
          "client_type": {
            "title": "PC.which-side",
            "type": "string",
            "default":attr['client_type']||'P',
            "enum": [
              "P",
              "D"
            ],
            "enumNames": [
              "Partie demanderesse",
              "Partie défenderesse"
            ]
          },
  
          "district":{
            "type":"string",
            "title":"PC.district",
            "default":attr["district"]||''
          },
          "area":{
            "type":"string",
            "title":"PC.area",
            "default":attr["area"]||''
          } ,
          "file_id":{
            "type":"string",
            "title":"PC.file_id",
            "default":attr["file_id"]||''
          },
  
          "manual":{
            type: 'boolean',
            title:'imprimer sans les pièces',
            default: false,
            enum: [false, true],
            
            // enumNames:[]
          },
  
          "pieces": {
            "type": "string",
            "title": 'PC.pieces',
            "default":attr[ 'pieces']||''
          },
          
          "pieces_list":pieces_list,

  
          //hidden fields
          "source":{
            "type":"string",
            "title":"source",
            "default":"APC"
          },
  
          "attachments":{
            "title":'',
            "type":"string",
            "default":JSON.stringify(attach),
          },
  
          "language":{
            "type":"string",
            "title":"language",
            "default":attr["language"]
          },
          "lawyer_zendesk":{
            "type":"string",
            "title":"lawyer_zendesk",
            "default":attr["lawyer_zendesk"]
          },
          "id_users":{
            "type":"string",
            "title":"id_users",
            "default":attr["id_users"]
          },
          "id":{
            "type":"string",
            "title":"id",
            "default":attr["id"]
          },
          "dossiername":{
            "type":"string",
            "title":"dossiername",
            "default":attr["dossiername"]
          },
          "dossier":{
            "type":"string",
            "title":"dossier",
            "default":attr["dossier"]
          },
          "guid":{
            "type":"string",
            "title":"guid",
            "default":attr["guid"]
          },
          "id_zendesk":{
            "type":"string",
            "title":"id_zendesk",
            "default":attr["id_zendesk"]
          },
        }
    };
    
    return fields;
};

export default schema;