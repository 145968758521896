import React from "react";
import { connect } from 'react-redux';
import rootsActions from '../../_actions/root-actions';
import {
    Button,
    ButtonGroup,
} from "reactstrap";

import { 
    Document,
    Page,
    pdfjs,
} from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// function base64ToArrayBuffer(base64: string) {
//     const binaryString = window.atob(base64); // Comment this if not using base64
//     const bytes = new Uint8Array(binaryString.length);
//     return bytes.map((byte, i) => binaryString.charCodeAt(i));
// }

class PDF extends React.Component {
    constructor(props){
        super(props);

        this._isMounted = false;
        this.state = {
            //reactjs work around ;_;
            zoom:this.props.zoom,
            numPages: null,
            pageNumber: 1,
            file:''
        }

        this.zoomPlus =this.zoomPlus.bind(this);
        this.zoomMinus =this.zoomMinus.bind(this);
        this.zoomInit =this.zoomInit.bind(this);
        this.genPDF = this.genPDF.bind(this);
        this.preview = this.preview.bind(this);

    }

    componentWillMount(){
        this._isMounted = true;
        // console.log('mounted !!')
        this.setState({
            numPages:null,
            zoom:1.2
        });
        // console.log(this);
    }

    componentWillUnmount(){
        console.log('UNMOUNTED')
        this._isMounted = false;
    }

    componentWillReceiveProps(prevProps,nextProps){
        console.log('---->',prevProps,nextProps)
    }
    zoomPlus(){
        this.setState({zoom:this.state.zoom+0.1});
    }

    zoomInit(){
        this.setState({zoom:0.65});
    }
    
    zoomMinus(){
        this.setState({zoom:this.state.zoom-0.1});
    }
    preview(){
        this.props.requestPDF()
    }

    onDocumentLoadSuccess = (document) => {
        if(!this._isMounted)
            return;
            
        const { numPages } = document;
        this.setState({ 
            numPages,
        });
    }

    genPDF(){
        if(!this._isMounted&&this.state.file)
            return;

        const   { numPages } = this.state;

        return(
            <Document
                file={(this.props.url)?this.props.url:''}
                onLoadError={console.error}
                onLoadSuccess={this.onDocumentLoadSuccess}>
                {
                    Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <Page
                                scale={this.state.zoom}
                                fillwidth
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                width={300}
                            />
                        ),
                    )
                }
            </Document>
        )
    }

    render() {
        

        let beforeMenu = <></>;
        let afterMenu = <></>;
        let menu = this.props.menu
        if(menu){
            if(menu.before){
                beforeMenu = (
                    <ButtonGroup aria-label="PDF Menu" right="true" className="d-none d-md-block d-lg-block d-xl-block">
                        {menu.before.tag &&
                            <Button type="button" color="warning" onClick={this.preview}><i className="fas fa-ticket-alt "></i></Button>
                        }
                        {menu.before.send &&
                            <Button type="button" color="primary" onClick={this.preview}><i className="fas fa-paper-plane"></i></Button>
                        }
                        {menu.before.save &&
                            <Button type="button" color="dark" onClick={this.preview}><i className="fas fa-save"></i></Button>
                        }
{/*                         
                        {menu.before.preview && this.props.forms.ticket &&
                            <Button type="button" color="success" onClick={this.props.back}><i className="far fa-file-pdf " ></i></Button>
                        } */}
                                                
                        {/* {menu.after.back &&
                            <Button type="button" color="warning" onClick={this.back}><i className="fas fa-undo" ></i></Button>
                        } */}
                    </ButtonGroup>);
            }

            if(menu.after){
                let  byte,blob;
                if(menu.after.save){
                    
                    byte = atob(this.props.url.replace(/^data:application\/pdf;base64,/,''))
                    let buffer = new ArrayBuffer(byte.length);
                    let view = new Uint8Array(buffer);
                    for (var i = 0; i < byte.length; i++) {
                        view[i] = byte.charCodeAt(i);
                    }
                    
                    blob = URL.createObjectURL(new Blob([view] , {type:'application/pdf'}));
                }
                afterMenu = (
                    <ButtonGroup aria-label="PDF Menu" right="true" vertical >
                        {menu.after.tag &&
                            <Button type="button" color="warning" size='sm'onClick={this.preview}><i className="fas fa-ticket-alt "></i></Button>
                        }
                        {menu.after.send &&
                            <Button type="button" color="primary" size='sm' onClick={this.download}><i className="fas fa-paper-plane"></i></Button>
                        }
                        {menu.after.save && !this.props.changed &&
                            <Button 
                                tag="a"
                                href={blob}
                                target="_blank"
                                download={this.props.ticket+'_pieces'}
                                type="button"
                                color="dark"
                                onClick={() => localStorage.removeItem('PC_save') }
                                size='sm'>
                                    <i className="fas fa-save"></i>
                            </Button>
                        }
                        {menu.after.preview &&
                            <Button type="button" color="success" size='sm' onClick={this.preview}><i className="far fa-file-pdf " ></i></Button>
                        }
                        <Button type="button" color="info" size='sm' onClick={this.zoomPlus}><i className="fas fa-search-plus" ></i></Button>
                        <Button type="button" color="info" size='sm' onClick={this.zoomInit}><i className="fas fa-search" ></i></Button>
                        <Button type="button" color="info" size='sm' onClick={this.zoomMinus}><i className="fas fa-search-minus" ></i></Button>
                    </ButtonGroup>);
            }
        }

        const viewStyle = (this.props.display === 'block')?'d-block':'d-xs-block d-sm-block overflow-x-allow weirdScroll content-top m-auto';
        return (
            <>
                <section id="PDF-preview" className={(this.props.url||!this.props.ticket)?'d-none':'content-top letter-format weirdScroll'}>
                    {beforeMenu}
                </section>
                <section 
                    id="PDF-area"
                    className={
                        (!this.props.url)?'d-none':viewStyle
                    }
                    >
                    <div className="high-z-index content-top">
                        {afterMenu}
                    </div>
                    
                    {(this.props.ticketVisible)?<div className={'PDF_name'}>{this.props.ticket?this.props.ticket:''}</div>:''}
                    {this.genPDF()}
                </section>
            </>
        );
    }
}

const mapStateToProps = state => ({
rootsActions:rootsActions,
forms:state.forms,
});

const mapActionsToProps = {
    requestPDF:rootsActions.formsActions.requestPDF
}

export default connect(mapStateToProps,mapActionsToProps)(PDF);