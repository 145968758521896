import React, {Component} from 'react';

import InputMask from 'react-input-mask';

export default class Postal extends Component{
    constructor(props){
        super(props);

    }

    render(){
        return <InputMask {...this.props} mask="*** ***" maskChar=" " className="form-control"/>;
    }
}